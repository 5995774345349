/* fonts */
@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('/public/fonts/ITC Avant Garde Gothic LT Extra Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('/public/fonts/ITC Avant Garde Gothic LT Bold.otf');
  font-weight: 600;
  font-style: bold;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('/public/fonts/ITC Avant Garde Gothic Book Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('/public/fonts/ITC Avant Garde Gothic Oblique.ttf');
  font-weight: 400;
  font-style: italic;
}

/* style */
body,
html,
#root {
  height: 100%;
  font-family: 'ITC Avant Garde Gothic';
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */

}

.pt-3,
.py-3 {
  padding-top: 2rem !important;
  padding-bottom: 10rem !important;
}

h1,
h2,
h3,
h5,
h6 {
  font-weight: bold;
  line-height: 1.5;
}

.bg-login {
  background: url('/public/images/login_bg.jpg') no-repeat center center fixed;
  background-size: cover;
}

.bg-main {
  background: linear-gradient(45deg, #FFD800, #66cdcc);
}

.bg-vw-dark {
  background-color: #2e2e2e;
}

.bg-vw-darkest {
  background-color: #2e2e2e;
}

.bg-vw-light {
  background-color: rgb(45, 204, 211);
}

.bg-vw-excel {
  background-color: #297148;
}

.text-vw-dark {
  color: rgb(0, 90, 125);
}

.text-vw-light {
  color: rgb(45, 204, 211);
}

.logo {
  height: 40px;
}

.navbar-nav .dropdown-menu {
  float: none;
  position: absolute !important;
  margin-left: -25vw !important;
}

.table {
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.table-clickable tr {
  cursor: pointer;
}

th,
td {
  text-align: center !important;
  vertical-align: middle !important;
  width: 100px;
}

.list-undecorated {
  list-style-type: none;
  padding: 0;
}

.text-break {
  white-space: pre-line;
  word-break: break-all;
}

.text-monospace {
  font-family: monospace;
}

.box-shadow {
  /*box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);*/
  box-shadow: 0.5rem 0.5rem 1rem rgb(53 172 191 / 25%);
}

.box-iconed {
  min-height: 100px;
  text-align: center;
}

.box-border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.badge {
  color: #ffffff;
}

@media (min-width: 1200px) {
  .container {
    max-width: 80vw !important;
  }
}

.salesCompute th {
  width: 2vw;
}

/*---------------------LOCKERS GUI---------------------*/

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
  text-align: center;
}

.dropdown-item {
  text-align: center !important;
}

#lockerBasic {
  width: 44px;
  height: 44px;
  margin: 1px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 2px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBasic:hover {
  width: 46px;
  height: 46px;
  margin: 0px;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBasic p {
  font-size: 12pt;
  color: #ffffff;
  font-weight: bold;
}

#lockerBasicXL {
  width: 44px;
  height: 90px;
  margin: 1px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 2px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBasicXL:hover {
  width: 46px;
  height: 92px;
  margin: 0px;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBasicXL p {
  font-size: 12pt;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 34px;
}

#lockerCharger {
  width: 44px;
  height: 44px;
  margin: 1px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 2px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCharger:hover {
  width: 46px;
  height: 46px;
  margin: 0px;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCharger p {
  font-size: 12pt;
  color: #ffffff;
  font-weight: bold;
}

#lockerChargerXL {
  width: 44px;
  height: 90px;
  margin: 1px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 2px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerChargerXL:hover {
  width: 46px;
  height: 92px;
  margin: 0px;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerChargerXL p {
  font-size: 12pt;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 34px;
}

#lockerEmpty {
  width: 44px;
  height: 44px;
  margin: 1px;
  text-align: center;
  background: rgba(0, 255, 255, 0);
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerKioskLeft {
  width: 45px;
  height: 136px;
  margin: 1px;
  margin-right: 0px;
  text-align: center;
  background: rgba(0, 255, 255, 0);
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
  background: url(/public/images/kiosk_left.png);
  background-size: cover;
}

#lockerKioskRight {
  width: 45px;
  height: 136px;
  margin: 1px;
  margin-left: 0px;
  text-align: center;
  background: rgba(0, 255, 255, 0);
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
  background: url(/public/images/kiosk_right.png);
  background-size: cover;
}

#lockerMatrix {
  height: 280px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 1012px;
  align-items: center;
}

#indicatorNull {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #0e0e0e;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#indicatorAvailable {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #35f734;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#indicatorRented {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #fc9700;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#indicatorError {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #35f734;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#indicatorErrorAdmin {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #4a7aff;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#indicatorErrorBattery {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #dd0202;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#kiosk {
  background: url(/public/images/machines/big.png);
  background-size: cover;
  width: 126px;
  height: 277px;
  margin-top: 0;
  position: absolute;
  margin-left: 489px;
  margin-right: auto;
  z-index: 1;
}

#matrixContainer {
  /*width: fit-content;*/
  overflow: auto;
}

#totalMatrixContainer {
  margin-left: auto;
  margin-right: auto;
  /* border: 2px solid #2dccd3; */
  padding: 1vw 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.totalMatrixIndicators {
  text-align: center;
  padding: 10px 30px 10px 30px;
  /* padding-right: 1vw; */
  border-left: 2px solid #00567d;
  border-right: 2px solid #00567d;
  border: 2px solid #2dccd3;
  margin: 10px;
  background-color: #00567d;
  color: #ffffff;
  border-radius: 10px;
}

.complete-width {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
}

.complete-width .form-group {
  width: 150px !important;
  margin-left: auto;
  margin-right: auto;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.button-center {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 1vw;
}

/* ANIMACIÓN DE CARGA DE TABLAS */


.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 150px;
  height: 150px;
  background-color: #2dccd3;
  border-radius: 50%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.spinner img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  animation: spin 1s linear infinite;
  z-index: 1;
}

.spinner::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #ccc;
  border-right-color: #00567d;
  border-left-color: #00567d;
  animation: spin 1s linear infinite;
  z-index: 0;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* PROMOTERS TABLE */

.timetable h6 {
  margin: 5px;
  width: 100px;
  margin-left: 15px;
}

.timetable label {
  margin: 5px;
  width: 50px;
  text-align: center;
}

/* MANUAL EXPEDITIONS HOME */

.bg-light-blue {
  background-color: #2dccd3 !important;
}

.bg-light-blue h5 {
  color: #ffffff !important;
}

.bg-light-blue h6 {
  color: #ffffff !important;
}

.bg-light-blue p {
  color: #ffffff !important;
}

.bg-light-blue .text-muted {
  color: #ffffff !important;
}

.bg-light-blue .bg-vw-light {
  background-color: #ffffff !important;
}


/* TEST 404 STARNGER THINGS*/

.c {
  text-align: center;
  display: block;
  position: relative;
  width: 80%;
  margin: 100px auto;
}

._404 {
  font-size: 220px;
  position: relative;
  display: inline-block;
  z-index: 2;
  height: 250px;
  letter-spacing: 15px;
}

._1 {
  text-align: center;
  display: block;
  position: relative;
  letter-spacing: 12px;
  font-size: 4em;
  line-height: 80%;
}

._2 {
  text-align: center;
  display: block;
  position: relative;
  font-size: 20px;
}

.text {
  font-size: 70px;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 19px 0px 0px 0px;
  z-index: 3;
  width: 100%;
  line-height: 1.2em;
  display: inline-block;
}

.right {
  float: right;
  width: 60%;
}

.cloud {
  width: 350px;
  height: 120px;

  background: #2dccd3;
  background: linear-gradient(to top, #2dccd3 100%);
  background: -webkit-linear-gradient(to top, #2dccd3 100%);
  background: -moz-linear-gradient(to top, #2dccd3 100%);
  background: -ms-linear-gradient(to top, #2dccd3 100%);
  background: -o-linear-gradient(to top, #2dccd3 100%);

  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;

  position: absolute;
  margin: 120px auto 20px;
  z-index: -1;
  transition: ease 1s;
}

.cloud:after,
.cloud:before {
  content: '';
  position: absolute;
  background: #2dccd3;
  z-index: -1
}

.cloud:after {
  width: 100px;
  height: 100px;
  top: -50px;
  left: 50px;

  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}

.cloud:before {
  width: 180px;
  height: 180px;
  top: -90px;
  right: 50px;

  border-radius: 200px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
}

.x1 {
  top: -50px;
  left: 100px;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
  opacity: 0.9;
  -webkit-animation: moveclouds 15s linear infinite;
  -moz-animation: moveclouds 15s linear infinite;
  -o-animation: moveclouds 15s linear infinite;
}

.x1_5 {
  top: -80px;
  left: 250px;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-animation: moveclouds 17s linear infinite;
  -moz-animation: moveclouds 17s linear infinite;
  -o-animation: moveclouds 17s linear infinite;
}

.x2 {
  left: 250px;
  top: 30px;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
  opacity: 0.6;
  -webkit-animation: moveclouds 25s linear infinite;
  -moz-animation: moveclouds 25s linear infinite;
  -o-animation: moveclouds 25s linear infinite;
}

.x3 {
  left: 250px;
  bottom: -70px;

  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
  opacity: 0.8;

  -webkit-animation: moveclouds 25s linear infinite;
  -moz-animation: moveclouds 25s linear infinite;
  -o-animation: moveclouds 25s linear infinite;
}

.x4 {
  left: 470px;
  botttom: 20px;

  -webkit-transform: scale(0.75);
  -moz-transform: scale(0.75);
  transform: scale(0.75);
  opacity: 0.75;

  -webkit-animation: moveclouds 18s linear infinite;
  -moz-animation: moveclouds 18s linear infinite;
  -o-animation: moveclouds 18s linear infinite;
}

.x5 {
  left: 200px;
  top: 300px;

  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0.8;

  -webkit-animation: moveclouds 20s linear infinite;
  -moz-animation: moveclouds 20s linear infinite;
  -o-animation: moveclouds 20s linear infinite;
}

@-webkit-keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }

  100% {
    margin-left: -1000px;
  }
}

@-moz-keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }

  100% {
    margin-left: -1000px;
  }
}

@-o-keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }

  100% {
    margin-left: -1000px;
  }
}



/*RESPONSIVE*/

@media (max-width: 1200px) {

  td,
  th {
    font-size: 8px;
    width: auto;
  }
}


/*PAGINATION INDEX*/

#page-numbers {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  max-width: 100vw;
  flex-wrap: wrap;
  padding: 10px;
}

#page-numbers li {
  padding: 5px 10px;
  border: 1px solid #2dccd3;
  color: #00567d;
  list-style-type: none;
  margin: 10px;
  border-radius: 5px;
}

#page-numbers li.active {
  color: #ffffff;
  cursor: pointer;
  background-color: #00567d;
}

#page-numbers li:hover {
  color: #ffffff;
  cursor: pointer;
  background-color: #00567d;
}


/* CUSTOM QR */

@media (min-width: 1200px) {
  .lockerContainer {
    padding-left: 35vw;
    padding-right: 35vw;
    padding-bottom: 10vw;
  }
}

.lockerContainer {
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 10vw;
}

.lockButton {
  color: #00567d;
  background-color: #ffffff;
  border: 2px solid #2e2e2e;
  border-radius: 20px;
  -webkit-box-shadow: 0.25rem 0.25rem 0rem rgb(53 172 191 / 50%);
  box-shadow: 0.25rem 0.25rem 0rem rgb(53 172 191 / 50%);
  height: 40vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vw;
}

.lockSoldOutButton {
  border: 2px solid #2e2e2e;
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  background-color: #0c000094;
  color: #fff !important;
  height: auto;
  -webkit-box-shadow: 0.25rem 0.25rem 0rem rgba(64, 75, 77, 0.5);
  box-shadow: 0.25rem 0.25rem 0rem rgba(64, 75, 77, 0.5);
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vw;
}

.lockSoldOutImgContainer {
  height: 40vw;
  width: 40vw;
  margin-top: 5vw;
}

.lockSoldOutImg {
  width: 40vw;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  z-index: 1;
}

.lockSoldOutCross {
  width: 40vw;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  z-index: 2;
}

.mapButton {
  color: #ffffff;
  background-color: #ffbb00;
  border: 2px solid #2e2e2e;
  border-radius: 20px;
  -webkit-box-shadow: 0.25rem 0.25rem 0rem rgba(191, 147, 53, 0.5);
  box-shadow: 0.25rem 0.25rem 0rem rgba(191, 147, 53, 0.5);
  height: 20vw;
  text-align: center;
  font-size: 7vw;
  font-weight: 800;
  position: fixed;
  bottom: 7vw;
  width: 90vw;
}

.fullscreenMapContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreenMapImage {
  max-height: 100%;
  max-width: 100%;
}

.modal-header .close {
  display: none;
}

.lockerSmallImage {
  width: 18vw;
  max-height: 90%;
  object-fit: scale-down;
  margin-left: auto;
  margin-right: auto;
}

.lockerTicketImage {
  width: 28vw;
  margin-left: auto;
  margin-right: auto;
}

.titleh5 {
  margin-top: -3vw;
}

.lockerBigImage {
  margin-left: 0;
  height: 32vw;
}

.zoneImage {
  margin-left: 10%;
  width: 110%;
  height: 80%;
  vertical-align: text-top;
}

.mapImage {
  width: 100%;
  height: 90%;
}

.pricesLockers {
  background: #00567d;
  border: 1px solid #000;
  border-radius: 18vw;
  color: #fff;
  font-size: 100%;
  font-weight: 700;
  margin-left: -60%;
  padding: 1vw;
  text-align: center;
  width: 21vw;
  align-content: center;
  align-content: center;
  margin-top: 64%;
  box-shadow: 0.1rem 0.1rem 0 #166669
}

.pingImage {
  height: 100%;
  /* margin-left: 8%; */
  /* margin-top: -3%; */
  /* vertical-align: text-top; */
  width: 100%;
}

.numping {
  font-size: 5vw;
  margin-left: 46%;
  margin-top: -94%;
  position: absolute;
}

.lockTitle {
  text-align: center;
}

.row {
  margin-left: 0;
  margin-right: 0;
  height: 100%;
}

.col {
  padding: 0;
}

.lockerCenterVertical {
  line-height: 33vw;
}

.lockerDescription {
  padding-top: 1vw;
  padding-right: 1%;
}

.colLockers {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 40vw;
  max-height: 100%;
}

.colPrice {
  width: 20vw;
}

.zoneDescription {
  line-height: 10vw;
  padding-top: 9vw;
  font-size: 120%;
}

.zoneDescription h5 {
  font-size: 7vw
}

.zoneDescription p {
  font-size: 7vw
}

.lockerAccessibilityImg {
  width: 40vw;
  margin-left: 25vw;
  margin-right: 25vw;
  margin-top: 5vw;
  margin-bottom: 10vw;
}

.lockerTextDescription {
  font-size: 5vw;
  top: 35%;
}

.titleLockers {
  top: 25%;
  position: relative;
}

.lockerAccessibilityButtonYes {
  color: #ffffff;
  background-color: #05a100;
  border: 2px solid #2e2e2e;
  border-radius: 20px;
  -webkit-box-shadow: 0.25rem 0.25rem 0rem rgba(53, 191, 87, 0.5);
  box-shadow: 0.25rem 0.25rem 0rem rgba(53, 191, 87, 0.5);
  height: 17vw;
  text-align: center;
  margin: 5vw;
}

.lockerAccessibilityButtonNo {
  color: #ffffff;
  background-color: #e60000;
  border: 2px solid #2e2e2e;
  border-radius: 20px;
  -webkit-box-shadow: 0.25rem 0.25rem 0rem rgba(191, 53, 53, 0.5);
  box-shadow: 0.25rem 0.25rem 0rem rgba(191, 53, 53, 0.5);
  height: 17vw;
  text-align: center;
  margin: 5vw;
}

.lockerPayButton {
  color: #ffffff;
  background-color: #ffbb00;
  border: 2px solid #2e2e2e;
  border-radius: 20px;
  -webkit-box-shadow: 0.25rem 0.25rem 0rem rgba(191, 147, 53, 0.5);
  box-shadow: 0.25rem 0.25rem 0rem rgba(191, 147, 53, 0.5);
  height: 17vw;
  text-align: center;
  font-size: 23pt;
  font-weight: 800;
}

.lockerErrorButton {
  color: #ffffff;
  background-color: #e91506;
  border: 2px solid #2e2e2e;
  border-radius: 20px;
  -webkit-box-shadow: 0.25rem 0.25rem 0rem rgba(191, 147, 53, 0.5);
  box-shadow: 0.25rem 0.25rem 0rem rgba(191, 147, 53, 0.5);
  height: 17vw;
  text-align: center;
  margin: 5vw;
}

.lockerDownloadButton {
  color: #ffffff;
  background-color: #ffbb00;
  border: 2px solid #2e2e2e;
  border-radius: 20px;
  box-shadow: 0.25rem 0.25rem 0 rgba(191, 147, 53, .5);
  color: #fff;
  text-align: center;
  margin-bottom: 6vw;
}

.lockerDownloadButton h3 {
  margin: 0px;
}

.lockerOpenButton {
  color: #ffffff;
  background-color: #3e8000;
  border: 2px solid #2e2e2e;
  border-radius: 20px;
  box-shadow: 0.25rem 0.25rem 0 rgba(191, 147, 53, .5);
  color: #fff;
  text-align: center;
  margin-bottom: 6vw;
}

.lockerOpenButton h3 {
  margin: 0px;
}

.lockerErrorButtonBig {
  color: #ffffff;
  background-color: #e91506;
  border: 2px solid #2e2e2e;
  border-radius: 20px;
  -webkit-box-shadow: 0.25rem 0.25rem 0rem rgba(191, 53, 53, 0.5);
  box-shadow: 0.25rem 0.25rem 0rem rgba(191, 53, 53, 0.5);
  height: 30vw;
  text-align: center;
  margin: 5vw;
  padding-bottom: 31vw;
}

.disabled {
  cursor: not-allowed;
  background: darkgrey;
  box-shadow: 0.25rem 0.25rem 0 #495057;
  opacity: .5;
}

.textcheckbox {
  color: #005a7d;
  text-align: left;
  padding-left: 25vw !important;
  margin-left: auto;
}

.divCheckbox {
  display: flex;
  position: relative;
  margin-bottom: 4vw;
  margin-top: 4vw;
}

.slide-checkbox {
  position: relative;
  padding-left: 30px;
  /* Adjust the padding as needed */
  cursor: pointer;
  display: flex;
  align-items: center;
}

.slider {
  position: absolute;
  top: 35%;
  left: 0;
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 25px;
  /* To make it look like a rounded slider */
  transition: 0.4s;
  border: 2px solid #2dccd3;
  margin-left: 6vw;
}

.slider:before {
  content: "";
  position: absolute;
  height: 20px;
  /* Adjust the size of the slider knob */
  width: 20px;
  /* Adjust the size of the slider knob */
  left: 1px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
  -webkit-box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.33);
  box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.33);
}

input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

input[type="checkbox"]:checked+.slider {
  background-color: #ffbb00;
  /* Change the background color when the checkbox is checked */
}

input[type="checkbox"]:checked+.slider:before {
  transform: translateX(25px);
  /* Move the slider knob to the right when the checkbox is checked */
}

.phoneInput {
  border-radius: 20px !important;
  height: 50px !important;
  margin-bottom: 6vw !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: 0px !important;
  width: 90VW !important;
  border: 1px solid #00567d !important;
  text-align: center !important;
}

.react-tel-input .flag-dropdown {
  background-color: #f5f5f5;
  border: 1px solid #cacaca;
  border-radius: 3px 0 0 3px;
  bottom: 0;
  padding: 0;
  position: absolute;
  top: 0;
  margin-left: 10vw;
  padding-top: 2px;
  padding-bottom: 2px;
}

.lockerTicket {
  border-style: dashed;
  padding: 25px;
  margin-bottom: 25px;
  border-color: #00567d;
}

.lockerInstructions {
  border-style: dashed;
  padding: 25px;
  margin-bottom: 25px;
  border-color: #00567d;
}

.dropdown {
  margin-right: 10px;
  border-radius: 4px;
  background-color: initial;
  background-image: none;
  border-color: #f8f9fa;
  color: #f8f9fa;
}

/*Countdown*/

.countdownTPV {
  background-color: #00567d;
  border-radius: 10px;
  bottom: 20px;
  left: -20px;
  color: #fff;
  /* position: fixed; */
  padding: 10px 20px;
  text-align: center;
  font-weight: bold;
  line-height: 10px;
  border: 2px solid #2dccd3;
}

/*  STEP BAR */

.step {
  width: 8vw;
  height: 8vw;
  border-radius: 100px;
  border: 2px solid #2dccd3;
  color: #2dccd3;
  background-color: #ffffff;
  text-align: center;
  font-weight: bold;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}

.stepActive {
  width: 8vw;
  height: 8vw;
  border-radius: 100px;
  border: 2px solid #2dccd3;
  color: #ffffff;
  background-color: #ffc400;
  text-align: center;
  font-weight: bold;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}

.stepOld {
  width: 8vw;
  height: 8vw;
  border-radius: 100px;
  border: 2px solid #2dccd3;
  color: #ffffff;
  background-color: #2dccd3;
  text-align: center;
  font-weight: bold;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}

.stepline {
  width: 8vw;
  height: 0.5vw;
  background-color: #2dccd3;
  text-align: center;
  border-radius: 100px;
  margin-top: 4vw;
}

.steplabel {
  text-align: center;
  border-radius: 100px;
  width: 16vw;
  position: absolute;
  color: #00567d;
}

.steplabelActive {
  text-align: center;
  border-radius: 100px;
  width: 20vw;
  position: absolute;
  font-weight: bold;
  font-size: 18px;
  margin-left: -2vw;
  color: #00567d;
}

.stepparent {
  display: block;
  justify-content: center;
  align-items: center;
  width: 16vw;
  /* Add any additional styling you want for the parent container */
  /* For example, you can set height and width if needed */
  /* height: 100px; */
  /* width: 200px; */
}

.stepbar {
  margin-bottom: 8vw;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

/* OTO CONTAINER */

.side {
  position: relative;
  width: 90vw;
  display: flex;
  justify-content: center;
  transition: background-color 0.3s;
  cursor: pointer;
  padding: 5vw;
  flex-direction: column;
  color: #00567d;
  background-color: #ffffff;
  border: 2px solid #2e2e2e;
  border-radius: 20px;
  -webkit-box-shadow: 0.25rem 0.25rem 0rem rgb(53 172 191 / 50%);
  box-shadow: 0.25rem 0.25rem 0rem rgb(53 172 191 / 50%);
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vw;
  align-items: center;
  /*height: 30vh;*/
}

.side img {
  height: 15vh;
  object-fit: contain;
}

/* ----------------------------------------------------------------- BAQUEIRA CUSTOMIZATION ----------------------------------------------------------- */

.bg-vw-dark-baqueira {
  background-color: #0A1D3D;
}

.bg-vw-light-baqueira {
  background-color: #E3E3E3;
}

.text-vw-dark-baqueira {
  color: #0A1D3D;
}

.text-vw-light-baqueira {
  color: #888888;
}

.logo-baqueira {
  height: 30px;
}

.lockTitleBaqueira {
  text-align: center;
  border-bottom: 1px solid #E3E3E3;
  width: 100%;
  display: block;
}

.spinner-baqueira {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 150px;
  height: 150px;
  background-color: #0A1D3D;
  border-radius: 50%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.spinner-baqueira img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  animation: spin 1s linear infinite;
  z-index: 1;
}

.spinner-baqueira::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #ccc;
  border-right-color: #E3E3E3;
  border-left-color: #E3E3E3;
  animation: spin 1s linear infinite;
  z-index: 0;
}

.lockLabel {
  background-color: #000;
  color: #fff;
  border-radius: 10px;
  /* width: 30vw; */
  /* display: flow; */
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
  min-width: 25vw;
  padding-left: 4vw;
  padding-right: 4vw;
}

.lockerContinueButtonBaqueira {
  color: #ffffff;
  background-color: #0A1D3D;
  border: 2px solid #2e2e2e;
  border-radius: 10px;
  -webkit-box-shadow: 0.25rem 0.25rem 0rem rgba(202, 202, 202, 0.5);
  box-shadow: 0.25rem 0.25rem 0rem rgba(202, 202, 202, 0.5);
  height: 17vw;
  text-align: center;
  font-size: 18pt;
  font-weight: 800;
  margin-top: 10vw;
  padding-left: 5vw;
  padding-right: 5vw;
  margin-left: 5vw;
  margin-right: 5vw;
}

.lockerPayButtonBaqueira {
  color: #ffffff;
  background-color: #0A1D3D;
  border: 2px solid #2e2e2e;
  border-radius: 20px;
  -webkit-box-shadow: 0.25rem 0.25rem 0rem rgba(202, 202, 202, 0.5);
  box-shadow: 0.25rem 0.25rem 0rem rgba(202, 202, 202, 0.5);
  height: 17vw;
  text-align: center;
  font-size: 23pt;
  font-weight: 800;
}

.lockerDownloadButtonBaqueira {
  color: #ffffff;
  background-color: #0A1D3D;
  border: 2px solid #2e2e2e;
  border-radius: 20px;
  -webkit-box-shadow: 0.25rem 0.25rem 0rem rgba(202, 202, 202, 0.5);
  box-shadow: 0.25rem 0.25rem 0rem rgba(202, 202, 202, 0.5);
  text-align: center;
  font-size: 18pt;
  font-weight: 800;
}

.lockerBackButtonBaqueira {
  color: #0A1D3D;
  background-color: #E3E3E3;
  border: 2px solid #2e2e2e;
  border-radius: 10px;
  -webkit-box-shadow: 0.25rem 0.25rem 0rem rgba(202, 202, 202, 0.5);
  box-shadow: 0.25rem 0.25rem 0rem rgba(202, 202, 202, 0.5);
  height: 17vw;
  text-align: center;
  font-size: 18pt;
  font-weight: 800;
  margin-top: 10vw;
  padding-left: 5vw;
  padding-right: 5vw;
  margin-left: 5vw;
  margin-right: 5vw;
}

.lockButtonBaqueira {
  color: #0A1D3D;
  background-color: #ffffff;
  border: 2px solid #2e2e2e;
  border-radius: 20px;
  -webkit-box-shadow: 0.25rem 0.25rem 0rem rgb(53 172 191 / 50%);
  box-shadow: 0.25rem 0.25rem 0rem rgb(53 172 191 / 50%);
  height: 40vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vw;
}

.colLockersBaqueira {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 50vw;
  max-height: 100%;
}

.lockerSmallImageBaqueira {
  max-height: 90%;
  object-fit: scale-down;
  margin-left: auto;
  margin-right: auto;
}

.quantity-baqueira {
  appearance: none;
  /* Remove default browser styling */
  background-color: #0A1D3D;
  /* Background color */
  color: white;
  /* Text color */
  border: none;
  /* Remove border */
  padding: 10px;
  /* Padding for better click area */
  border-radius: 5px;
  /* Rounded corners */
  font-size: 16px;
  /* Font size */
  cursor: pointer;
  /* Pointer cursor to indicate clickability */
  transition: background-color 0.3s;
  /* Smooth background color transition */
}

.quantity-baqueira:hover {
  background-color: #0A1D3D;
  /* Darker blue when hovering */
}

.quantity-baqueira:focus {
  outline: none;
  /* Remove the default outline */
  box-shadow: 0 0 5px 2px #0A1D3D;
  /* Add a glow effect when focused */
}

.dates-baqueira {
  appearance: none;
  /* Remove default browser styling */
  background-color: #0A1D3D;
  /* Background color */
  color: white;
  /* Text color */
  border: none;
  /* Remove border */
  padding: 10px;
  /* Padding for better click area */
  border-radius: 5px;
  /* Rounded corners */
  font-size: 16px;
  /* Font size */
  cursor: pointer;
  /* Pointer cursor to indicate clickability */
  transition: background-color 0.3s;
  /* Smooth background color transition */
  text-align: center;
  ;
}

.dates-baqueira:hover {
  background-color: #0A1D3D;
  /* Darker blue when hovering */
}

.dates-baqueira:focus {
  outline: none;
  /* Remove the default outline */
  box-shadow: 0 0 5px 2px #0A1D3D;
  /* Add a glow effect when focused */
}

.disabled-baqueira {
  opacity: 0.2;
}

.countdownTPV-baqueira {
  background-color: #0A1D3D;
  border-radius: 10px;
  bottom: 20px;
  left: -20px;
  color: #fff;
  /* position: fixed; */
  padding: 10px 20px;
  text-align: center;
  font-weight: bold;
  line-height: 10px;
  border: 2px solid #E3E3E3;
}

.lockerTicket-baqueira {
  border-style: dashed;
  padding: 25px;
  margin-bottom: 25px;
  border-color: #E3E3E3;
}

/*  STEP BAR */

.stepBaqueira {
  width: 8vw;
  height: 8vw;
  border-radius: 100px;
  border: 2px solid #E3E3E3;
  color: #0A1D3D;
  background-color: #ffffff;
  text-align: center;
  font-weight: bold;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}

.stepActiveBaqueira {
  width: 8vw;
  height: 8vw;
  border-radius: 100px;
  border: 2px solid #0A1D3D;
  color: #0A1D3D;
  background-color: #E3E3E3;
  text-align: center;
  font-weight: bold;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}

.stepOldBaqueira {
  width: 8vw;
  height: 8vw;
  border-radius: 100px;
  border: 2px solid #E3E3E3;
  color: #ffffff;
  background-color: #E3E3E3;
  text-align: center;
  font-weight: bold;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}

.steplineBaqueira {
  width: 8vw;
  height: 0.5vw;
  background-color: #E3E3E3;
  text-align: center;
  border-radius: 100px;
  margin-top: 4vw;
}

.steplabelBaqueira {
  text-align: center;
  border-radius: 100px;
  width: 16vw;
  position: absolute;
  color: #0A1D3D;
}

.steplabelActiveBaqueira {
  text-align: center;
  border-radius: 100px;
  width: 20vw;
  position: absolute;
  font-weight: bold;
  font-size: 18px;
  margin-left: -2vw;
  color: #0A1D3D;
}

/* SLIDER */

.textcheckbox-baqueira {
  color: #0A1D3D;
  text-align: left;
  padding-left: 25vw !important;
  margin-left: auto;
}

.slider-baqueira {
  position: absolute;
  top: 35%;
  left: 0;
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 25px;
  /* To make it look like a rounded slider */
  transition: 0.4s;
  border: 2px solid #888888;
  margin-left: 6vw;
}

.slider-baqueira:before {
  content: "";
  position: absolute;
  height: 20px;
  /* Adjust the size of the slider knob */
  width: 20px;
  /* Adjust the size of the slider knob */
  left: 1px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
  -webkit-box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.33);
  box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.33);
}

input[type="checkbox"]:checked+.slider-baqueira {
  background-color: #0A1D3D;
  /* Change the background color when the checkbox is checked */
}

input[type="checkbox"]:checked+.slider-baqueira:before {
  transform: translateX(25px);
  /* Move the slider knob to the right when the checkbox is checked */
}

.terms-link {
  color: #2e2e2e; /* Example link color */
  text-decoration: underline;
  cursor: pointer;
}

.terms-link:hover {
  text-decoration: none;
}

/* Apply only on screens larger than 550px */
@media (min-width: 550px) {
  .viewport-simulator {
    width: 430px; /* Fixed width to simulate smartphone */
    margin: 0 auto; /* Center the content horizontally */
    border: 1px solid #ccc; /* Optional: Add a border for clarity */
    height: 100%; /* Match the viewport height */
    overflow-y: auto; /* Allow vertical scrolling */
    background: white; /* Ensure the simulated viewport is visible */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: Add shadow for effect */
  }
}